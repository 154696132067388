<template>
  <b-card>
    <h4>Qui potrai creare un account interno per i tuoi collaboratori o clienti</h4>
    <b-form @submit.prevent="registerInternalUser">
      <v-select
        v-model="selected"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        multiple
        label="title"
        placeholder="Seleziona ruolo/i"
        :options="option"
        @open="loadRole"
      >
        <!-- eslint-disable-next-line vue/no-unused-vars  -->
        <template #no-options="{ open, searching, loading }">
          Caricamento Ruoli
        </template>
      </v-select>
      <b-row>
        <b-col>
          <b-form-group
            label="Inserisci Nome"
            label-for="internal-account-register-name"
            label-class="font-weight-bolder"
          >
            <b-form-input
              id="internal-account-register-name"
              v-model="name"
              placeholder="es. Mario"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Inserisci Cognome"
            label-for="internal-account-register-surname"
            label-class="font-weight-bolder"
          >
            <b-form-input
              id="internal-account-register-surname"
              v-model="surname"
              placeholder="es. Rossi"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Inserisci Email"
            label-for="internal-account-register-email"
            label-class="font-weight-bolder"
          >
            <b-form-input
              id="internal-account-register-email"
              v-model="email"
              placeholder="es. paolo.rossi@gmail.com"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            label="Inserisci Ruolo"
            label-for="internal-account-register-role"
            label-class="font-weight-bolder"
          >
            <b-form-input
              id="internal-account-register-role"
              v-model="role"
              disabled
              placeholder="es. Amministratore"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Inserisci Telefono"
            label-for="internal-account-register-phone"
            label-class="font-weight-bolder"
          >
            <b-form-input
              id="internal-account-register-phone"
              v-model="phone"
              placeholder="es. Rossi"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Inserisci Piano"
            label-for="internal-account-register-plan"
            label-class="font-weight-bolder"
          >
            <b-form-input
              id="internal-account-register-plan"
              v-model="plan"
              placeholder="es.free"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-button
        type="submit"
        variant="primary"
      >
        Registra
      </b-button>
      {{ selected }}
    </b-form>
  </b-card>
</template>

<script>
import {
  BRow, BCol, BCard, BForm, BFormGroup, BFormInput, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

import { filterErrorResponseApi } from '@core/utils/filter'
import axios from 'axios'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    vSelect,
  },
  data() {
    return {
      errorsResponse: [],
      token: `Bearer ${sessionStorage.getItem('token')}`,
      name: '',
      surname: '',
      email: '',
      role: 'Utente',
      phone: '',
      plan: 'FREE',
      dir: 'ltr',
      selected: [],
      option: [],

    }
  },
  mounted() {
    // console.log('ciao')
    // this.loadRole()
  },
  methods: {
    prepareRoleForServer() {
      // console.log(this.selected.map(role => role.title))
      return this.selected.map(role => role.title)
    },
    async loadRole() {
      await axios.post(process.env.VUE_APP_URL_API_SERVER, {},
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            organizzazione: JSON.parse(sessionStorage.getItem('activeAgency')).value,
            classe: 'Acl',
            metodo: 'getRuoli',
          },
        }).then(res => {
        this.option = res.data.ruoli.map(role => ({ title: role.ruolo, id: role.id }))
      })
        .catch(e => e)
    },
    async registerInternalUser() {
      this.prepareRoleForServer()
      // console.log('name', this.name)
      // console.log('surname', this.surname)
      // console.log('email', this.email)
      // console.log('role', this.role)
      // console.log('phone', this.phone)
      // console.log('plan', this.plan)
      // console.log(JSON.parse(sessionStorage.getItem('activeAgency')).value)
      await axios.post(process.env.VUE_APP_URL_API_SERVER, {
        name: this.name,
        cognome: this.surname,
        email: this.email,
        ruolo: this.role,
        cellulare: this.phone,
        piano: this.plan,
        lingua: 'IT',
        // description: this.description,
        // token: this.token,
      },
      {
        headers: {
          Authorization: this.token,
          organizzazione: JSON.parse(sessionStorage.getItem('activeAgency')).value,
          classe: 'Utente',
          metodo: 'registrazioneUtenteInterno',
        },
      }).then(res => {
      // console.log('sei dentro lo store/resetPassword')
        // console.log(res)
        if (res.data.esito === 'OK' && res.data.response.original.esito === 'OK') {
          this.$swal({
            title: res.data.response.original.messaggio,
            // text: 'Controlla la posta per confermare',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        }
        if (res.data.response.original.esito === 'KO') {
          this.errorsResponse = filterErrorResponseApi(res.data.response.original.messaggio)
          // console.log('2', this.errorsResponse)
          this.$swal.fire({
            title: '<strong><u>Errore</u></strong>',
            icon: 'error',
            html: this.errorsResponse.map(item => `<p class="text-left">${item}</p>`).join(''),
            showCloseButton: true,
            showCancelButton: true,
            focusConfirm: false,
            customClass: {
              confirmButton: 'btn btn-primary',
            },
          })
        }
      })
        .catch(e => {
          // console.log(e)
          if (e) {
            this.$swal({
              title: 'Errore',
              text: 'Controlla i dati inseriti',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          }
        })
    },
  },
}
</script>

<style lang="scss">

</style>
